import React from 'react';

export const ResumeReachMe = () => {
    return (
        <div className="reachMe">
           <div>Astoria, NYC</div>
           <div><b>(310) 993-6408</b></div>
           <div><b>taycmoore2009@gmail.com</b></div>
        </div>
    );
  }