import React from 'react';

export const Home = () => {
    return (
        <div className="homepage">
          <div className="header">
            <h1>Coding Wizard</h1>
            <div className="codingImg"></div>
          </div>
        </div>
    );
  }